import React from "react"
import { graphql } from 'gatsby'
import HomePageLayout from '../components/layouts/layout-homepage.js'
import DocsLinks from '../components/docsLinks.js'
import DocsResources from '../components/docsResources.js'

export const query = graphql`
  query HomePageQuery {
    allDocsInfoYaml(filter: {productId: {eq: "commerce-cloud"}}) {
      nodes {
        id
        productId
        productName {
          currentName
          formerName
        }
        productIntro
        docs {
          description
          title
          links {
            href
            label
          }
        }
        resources {
          title
          imageFile
          description
          link {
            href
            label
          }
        }
      }
    }
  }
`

const HomePage = ({data}) => {
  const queryData = data.allDocsInfoYaml.nodes[0]
  const productName = queryData.productName
  const productIntro = queryData.productIntro
  const pageInfo = {
    title: "Elastic Path Composable Commerce Documentation - How-to guides (Tutorials) and Developer Tools",
    description: "Browse documentation for Elastic Path Composable Commerce. Build your commerce solutions, get help from the how-to guides, and find answers to frequently asked questions.",
    url: "https://documentation.elasticpath.com/"
  }

  return (
    <HomePageLayout
      pageInfo={pageInfo}
      productName={productName}
      productIntro={productIntro}
      theme="OrangeOnDeepBlue">

      <section className="docs">
        <DocsLinks data={queryData.docs} />
      </section>

      <section className="resources">
        <div className="ep-card">
          <h2 className="ep-card-title">Explore more {productName.currentName} resources</h2>
          <DocsResources data={queryData.resources} />
        </div>
      </section>

    </HomePageLayout>
  )
}

export default HomePage
